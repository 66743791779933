<template>
  <transition name="fade">
    <div
      v-if="modalState.isVisible"
      class="modal-overlay"
      @click.self="onClickToClose"
    >
      <div
        :class="resolvedProps.classes"
        :style="resolvedProps.style"
        @click.stop
      >
        <button
          v-if="resolvedProps.closeButton"
          class="close-button"
          @click="closeModal"
        >
          ✕
        </button>
        <!-- Modal header -->
        <div :class="'modal-header-' + modalState.modalId" />
        <!-- Modal body -->
        <div
          :id="modalState.modalId"
          :style="resolvedProps.bodyStyle"
          class="scrollable-content"
        >
          <component
            :is="modalState.currentModal.component"
            v-bind="modalState.currentModal.props"
            @close="closeModal"
            @modal-event="handleDataEmitted"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { toRefs, computed, nextTick } from 'vue';

export default {
  props: {
    modalState: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { modalState } = props;

    // Log modalState to ensure modalEventHandler is present
    console.log('modalState at setup:', modalState);

    const resolvedProps = computed(() => {
      const inputClasses = modalState.currentModal.options.classes;
      const additionalClasses = Array.isArray(inputClasses) ? inputClasses : (inputClasses ? [inputClasses] : []);
      const width = modalState.currentModal.options.width || 'auto';
      const height = modalState.currentModal.options.height || 'auto';
      const overflowY = modalState.currentModal.options.overflowY || 'auto';

      return {
        closeButton: modalState.currentModal.options.closeButton ?? false,
        clickToClose: modalState.currentModal.options.clickToClose ?? true,
        classes: ['modal-content', ...additionalClasses],
        style: { width, height },
        bodyStyle: { overflowY },
      };
    });

    const closeModal = () => {
      modalState.isVisible = false;
      modalState.close();
    };

    const onClickToClose = () => {
      if (resolvedProps.value.clickToClose) {
        closeModal();
      }
    };

    const moveModalHeaderContent = () => {
      nextTick(() => {
        const customHeader = document.querySelector('.custom-modal-header');
        const modalHeader = document.querySelector('.modal-header-' + modalState.modalId);
        if (customHeader && modalHeader) {
          modalHeader.appendChild(customHeader);
        }
      });
    };

    const handleDataEmitted = (data) => {
      console.log('Data received from child component:', data);
      if (modalState.modalEventHandler) {
        console.log('Calling modalEventHandler');
        modalState.modalEventHandler(data);
      } else {
        console.log('modalEventHandler not found in modalState');
      }
    };

    return {
      ...toRefs(modalState),
      closeModal,
      onClickToClose,
      resolvedProps,
      moveModalHeaderContent,
      handleDataEmitted,
    };
  },
  mounted() {
    this.moveModalHeaderContent();
  },
};
</script>


<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1000;
}

.modal-content {
    position: relative;
    background: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);
    max-width: 90%;
    max-height: 90vh;
    width: auto;
    height: auto;
    box-sizing: border-box;
}

.scrollable-content {
    max-height: 80vh;
    overflow-y: auto;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    background-color: #4b5563;
    color: #fff;
    height: 30px;
    width: 30px;
    border-radius: 50%;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease-out, transform 0.3s ease-out;
    transform-origin: center;
}

.fade-enter,
.fade-leave-to
{
    opacity: 0;
    transform: scale(1.05);
}
</style>