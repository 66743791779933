<template>
  <div>
    <div class="flex flex-wrap justify-between items-top mt-2 mb-4">
      <!-- TODO: refactor headlines - maybe move to sub parts -->
      <div class="flex">
        <button
          v-if="$route.params.processId &&
            !$route.params.component &&
            !$route.params.documentId
          "
          @click="goBack()"
        >
          <font-awesome-icon
            class="mr-4 w-8 h-6 p-3 rounded-md hover:bg-gray-200"
            icon="fa-arrow-left"
          />
        </button>
        <div class="text-2xl font-semibold ml-5 mb-2 lg:mb-0">
          <div
            v-if="$route.params.processId &&
              !$route.params.component &&
              !$route.params.documentId
            "
          >
            <div class="flex items-center">
              {{ openProcess.display_name }}
              <span
                class="bg-gray-200 text-blue-800 text-xs font-semibold px-2 py-0.5 rounded dark:bg-aktio-darkblue-900 dark:text-blue-900 h-full ml-1"
              >
                {{ openProcess.document_count > 1 ? `${openProcess.document_count} dokumenter` :
                  openProcess.document_count === 1 ? `1 dokument` : '0 dokumenter' }}
              </span>
            </div>
            <div
              v-if="openProcess.law_set"
              class="text-xs flex space-x-2"
            >
              <a
                class="pt-0.5 px-2 bg-gray-300 rounded-sm mr-1"
                :title="openProcess.law_set.display_name"
              >{{
                openProcess.law_set.name }}</a>
              <span class="font-light text-sm text-gray-500">
                Sagsid: {{ openProcess.process_id }}
                <button
                  v-if="canCopy"
                  class="mx-4"
                  @click="copy(openProcess.process_id, 'Sagsid')"
                >
                  <case-icon-copy class="h-3 w-3" />
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="$route.params.processId && openProcess"
        class="py-2 px-4 rounded bg-white shadow-sm"
      >
        <redaction-process-dates :process="openProcess" />
      </div>
      <div class="space-x-2">
        <standard-button
          v-if="$route.params.processId && !$route.params.documentId && !$route.params.decision && user.userData.acadre_username"
          button-size="small"
          button-text="Hent dokumenter"
          button-icon-fa="fa-file-export"
          button-type="standard"
          @click="openImportExtDocModal()"
        />
        <standard-button
          v-if="$route.params.processId && !$route.params.documentId && !$route.params.decision"
          button-text="Del sag"
          button-icon="share"
          @click="openShareCaseModal"
        />
        <!-- <standard-button
          v-if="$route.params.processId && !$route.params.documentId && !$route.params.decision"
          button-text="Se brev og aktliste"
          button-icon="pdf"
          :button-type="files.length===0 ? 'standard-disableable' : 'standard'"
          :disabled="files.length===0"
          :button-disabled="files.length===0"
          @click="files.length!==0 && showDecisionDocuments()"
        /> -->
        <standard-button
          v-if="$route.params.processId && !$route.params.documentId && !$route.params.decision"
          :button-text="goToDecisionLetterText"
          button-icon="pdf"
          :button-type="isButtonDisabled ? 'standard-disableable' : 'standard'"
          :disabled="isButtonDisabled"
          :button-disabled="isButtonDisabled"
          @click="!isButtonDisabled && showDecisionDocuments()"
        />
        <standard-button
          v-if="$route.params.processId && openProcess && openProcess.process_status && openProcess.process_status.is_finished"
          button-text="Genåben sagen"
          button-icon-fa="fa-rotate-right"
          @click="reopenProcess"
        />
        <standard-button
          v-else-if="$route.params.processId && !$route.params.documentId && $route.params.decision"
          button-text="Afslut sagen"
          button-type="success"
          button-icon-fa="fa-check"
          @click="openConfirmCloseCaseModal"
        />
        <standard-button
          v-else-if="$route.params.processId && !$route.params.documentId && $route.params.decision"
          :button-disabled="true"
          button-type="standard-disableable"
          button-text="Afslut sagen"
          button-icon-fa="fa-check"
        />
      </div>
    </div>
    <!-- BEGIN process table-->
    <redaction-process-table />
    <!-- END process table-->
    <FileUploadBox
      ref="fileUploadBoxComponent"
      v-if="$route.params.processId && !$route.params.documentId && !$route.params.decision"
      :should-open-files-selector="shouldOpenFilesSelector"
      @file-upload-block="blockFilesWhenUploading"
    />
    <!-- BEGIN document table-->
    <div
      v-if="$route.params.decision"
      class="w-full"
    >
      <decision-overview />
    </div>
    <div
      v-else-if="$route.params.processId && !$route.params.documentId"
      class="w-full"
    >
      <redaction-document-table :block-files="blockFiles" />
      <!-- <document-list :documents="files" /> -->
    </div>
    <!-- END process detail-->
  </div>
</template>

<script>
import { mapState } from "vuex";
import FileUploadBox from "@/components/FileUploadBoxNew.vue";
import documentTable from "@/components/DocumentTable.vue";
import ProcessTable from "@/components/ProcessTable.vue";
import EditCaseForm from "@/components/forms/EditCaseForm.vue";
import ConfirmCloseCaseForm from "@/components/forms/ConfirmCloseCaseForm.vue";
import FormShareCase from "@/components/forms/ShareCaseForm.vue";
import ProgressDates from "@/components/controls/ProcessDates.vue";
import StandardButton from "@/components/controls/StandardButton.vue";
import DecisionOverview from '@/components/DecisionOverview.vue';
import iconCopy from "@/components/icons/copy.vue";
import ConfirmRestoreOptions from "@/components/forms/ConfirmRestoreOptions.vue";
import DenialProcessModal from "@/components/forms/DenialProcessModal.vue";
import ImportExternalDocumentsForm from "@/components/forms/ImportExternalDocumentsForm.vue";

export default {
  name: "Processes",
  components: {
    FileUploadBox,
    "redaction-process-dates": ProgressDates,
    "redaction-document-table": documentTable,
    "redaction-process-table": ProcessTable,
    "decision-overview": DecisionOverview,
    StandardButton,
    "case-icon-copy": iconCopy,
  },
  data() {
    return {
      blockFiles: false,
      shouldOpenFilesSelector: false,
      canCopy: false,
    };
  },
  computed: {
    ...mapState([
      "openFile",
      "user",
      "files",
      "processes",
      "openProcess",
      "lawsLoaded",
      "processStatus",
      "searchPDFResults",
    ]),
    isButtonDisabled() {
      if (!Array.isArray(this.files)) {
        return true; // Disable button if files is not an array or is empty
      }

      const allFilesUploaded = this.files.every(file =>  
        file.document_status && 
        ['Indlæst', 'Undtaget', 'Færdigbehandlet'].includes(file.document_status.display_name)
      );

      return !allFilesUploaded;
    },
    goToDecisionLetterText() {
      if (this.openProcess.law_set && this.openProcess.law_set.name === "INS") {
        return "Se brev og dokumentsamling"
      } else {
        return "Se brev og aktliste"
      }
    },
  },
  async beforeCreate() {
    await this.$store.dispatch("loadDocumentTypes");
    await this.$store.dispatch("loadDocumentStatus");
    // load process data form API
    await this.$store.dispatch("loadProcessesFromApi");
    if (this.lawsLoaded === false) {
      await this.$store.dispatch("loadLaws");
    }
  },
  created() {
    this.canCopy = !!navigator.clipboard;
  },
  methods: {
    triggerFileUpload() {
      this.shouldOpenFilesSelector = true;
      // Toggle the state back to the default to allow
      // for the user to trigger it multiple times.
      // 500 ms timeout to ensure the state has time to propagate
      setTimeout(() => {
        this.shouldOpenFilesSelector = false
      }, 500);
    },
    goBack() {
      if (this.$route.params.decision) {
        this.$router.push({ name: "Processes", params: { processId: this.openProcess.process_id } });
      } else {
        this.$router.push({ name: "Processes", params: {} });
      }

    },
    blockFilesWhenUploading(isBlocked) {
      this.blockFiles = isBlocked;
    },
    openNewCaseModal() {
      this.$modal.show(
        EditCaseForm,
        {},
        {
          classes: 'overflow-visible',
          height: "auto",
          scrollable: true,
          clickToClose: false,
        },
        {
          // closed: this.clearDocumentation,
        }
      );
    },
    openConfirmCloseCaseModal() {
      this.$modal.show(ConfirmCloseCaseForm);
    },
    async showDecisionDocuments() {
      console.log("Check file length", this.files)
      const processInfo = await this.$store.dispatch("getRestorationStatus", {
          processId: this.openProcess.process_id,
      });
      if (this.files.length === 0 && 
        processInfo.restorationStatus !== "NoChange" && 
        (this.openProcess.law_set.name === "FVL" || this.openProcess.law_set.name === "OFL" || this.openProcess.law_set.name === "MOL")) {
          this.$modal.show(
          DenialProcessModal,
          {
            processId: this.$route.params.processId,
            decisionLetterId: processInfo.decisionLetterId,
            denialLaw: this.openProcess.law_set.name
          },
          {
            height: "600px",
            width: "800px",
            draggable: false,
            scrollable: true,
            clickToClose: false
          }
        );
      } else if (processInfo.restorationStatus === "NoChange" || processInfo.restorationStatus === "NotCreated") {
        this.$router.push({ name: "Decision", params: { processId: this.openProcess.process_id, decision: "beslutning" } });
      } else {
        this.$modal.show(
          ConfirmRestoreOptions,
          {
            processId: this.$route.params.processId,
            decisionLetterId: processInfo.decisionLetterId,
          },
          {
            draggable: false,
            scrollable: true,
            clickToClose: false,
          }
        );
      }
    },
    // TODO: consolidate with ProcessTable method
    clearDocumentation(evt) {
      if (evt.state === "closed") {
        this.$store.commit("clearDocumentationToUpload");
      }
    },
    openShareCaseModal() {
      this.$modal.show(FormShareCase,
        {},
        { classes: 'overflow-visible' },);
    },
    async closeProcess() {
      // Find the finished process status
      const finishedProcessStatus = this.processStatus.find(({ is_finished }) => is_finished)
      if (finishedProcessStatus) {
        // To update the process status, the id + new process status is needed
        const projectObjectWithFinishedProcessStatus = { id: this.openProcess.id, process_status: finishedProcessStatus }
        // Update the status
        await this.$store.dispatch("updateProcessStatus", projectObjectWithFinishedProcessStatus);
        // Navigate to the process overview
        this.$router.push("/processes");
      }
    },
    async reopenProcess() {
      // Find the default process status
      const openProcessStatus = this.processStatus.find(({ is_default_value_for_new }) => is_default_value_for_new)
      if (openProcessStatus) {
        // To update the process status, the id + new process status is needed
        const projectObjectWithOpenProcessStatus = { id: this.openProcess.id, process_status: openProcessStatus }
        // Update the status
        await this.$store.dispatch("updateProcessStatus", projectObjectWithOpenProcessStatus);
        // Reload the process data to reflect the new process status
        await this.$store.dispatch("loadOnlyProcess", this.$route.params.processId);
      }
    },
    async copy(s, text) {
      await navigator.clipboard.writeText(s);
      const toast_message = text + ' er kopieret til udklipsholderen.';
      this.$showRegisterToast(toast_message, "info", 4000, {})
      this.$store.dispatch("posthogCapture", { event_name: "CopyText", data: { copyText: text } });
    },
    openImportExtDocModal() {
      this.$store.dispatch("posthogCapture", {event_name:"ImportExternalDocuments", data:{}});

      this.$modal.show(
        ImportExternalDocumentsForm,
        {
          processId: this.openProcess.process_id,
          internalId: this.openProcess.id
        },
        {
          classes: 'overflow-visible',
          height: "100%",
          width: "75%",
          shiftY: 0.15,
          scrollable: true,
          clickToClose: false,
          draggable: false,
        },
        {
          modalEventHandler: this.startPolling,
        }
      );
    },
    startPolling() {
      this.$refs.fileUploadBoxComponent.pollDocumentStatusesNormal()
    },
  },
};
</script>